import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from './app-loader/app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

    constructor(private router: Router, private toastr: ToastrService, private loader: AppLoaderService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
	        tap(event => {
	          if (event instanceof HttpResponse) {


                if (event.status !== 200) {
                    if (event.body && event.body.message) {
                        this.loader.infoMessage(event.body.message)
                    }
                }
	          }
	        }, (error) => {

                    // Check if this is a login request
                    const isLoginRequest = request.url.endsWith('/login');

                    if (error.status === 401 && !isLoginRequest) {
                        // Only show session expired for non-login requests
                        this.toastr.info('Session Expired. Please Login Again');
                        this.router.navigate(['/auth/login']);
                        this.loader.close();
                    } else if (!isLoginRequest) {
                        // Show error messages for non-login requests
                        this.loader.errorMessage(error.error.message)
                    }

            })
	      );

    };
}
