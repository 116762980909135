import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
  }

  goToDashboard() {
    const isLawyer = localStorage.getItem('isLawyer') === 'true';
    const route = isLawyer ? environment.navigation.lawyerDashboard : environment.navigation.defaultDashboard;
    this.router.navigate([route]);
  }

  goBack() {
    this.location.back();
  }
}
