<div class="container no-access">
  <div fxLayout="row" fxLayoutAlign="center center" style="min-height: 80vh;">
    <mat-card class="no-access-card">
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div class="error-code">404</div>
          <h1 class="header-title">Page Not Found</h1>
          <p class="message">We couldn't find the page you're looking for.</p>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" class="action-buttons">
            <button mat-raised-button color="primary" (click)="goToDashboard()">
              <mat-icon class="mr-8">dashboard</mat-icon>
              <span>Go to Dashboard</span>
            </button>
            <button mat-stroked-button (click)="goBack()">
              <mat-icon class="mr-8">arrow_back</mat-icon>
              <span>Go Back</span>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
